//import Validate from "@/plugins/Validation/Branch/branch"
import queryOption from "../../Helpers/queryOption";
import {GetOldValueOnInput} from "../../Helpers/GetValue";

export default {
    props: ["items"],
    data() {
        return {
            loading: false,
            TableLoading: false,
            has_route_plan:0,
            selectedRow:[],
            customers: [],
            districts: [],
            listVillages: [],
            selectedVillages: [],
            selectedDistrict: [],
            routePlan:{},
            search:"",

            //Pagination
            offset: 15,
            pagination: {},
            per_page: 80,

            headers: [
                {
                    text: "ລະຫັດລູກຄ້າ",
                    align: "start",
                    sortable: false,
                    value: "customer_code",
                }, {
                    text: "ຊື່ລູກຄ້າ",
                    align: "start",
                    sortable: false,
                    value: "name",
                },
                {text: "ເບີໂທ", value: "phone"},
                {text: "ບ້ານ", value: "village"},
                {text: "ເມືອງ", value: "district"},
                {text: "ຈຸດສັງເກດ", value: "ຈຸດສັງເກດ"},
            ],

            //Map
            latlng: {
                lat: 18.1189434,
                lng: 102.290218,
            },
            markers: [],
            places: [],
            currentPlace: null,
            infoPosition: null,
            infoContent: null,
            infoOpened: false,
            infoCurrentKey: null,
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35,
                },
            },

            idRules: [
                v => !!v || 'Truck ID is required',
                v => (v && v.length >= 2) || 'Truck ID must be more than 5 characters',
            ],
            phoneRules: [
                (v) => !!v || "Phone is required",
                v => (v && v.length >= 4 && v.length <= 11) || 'Phone number must be  4 - 11 numbers',
            ],

            toast: {
                value: true,
                color: 'success',
                msg: 'Success'
            },
            toast_error: {
                value: true,
                color: 'error',
                msg: 'Something when wrong!'
            }
        }
    },
    methods: {
        backPrevios(){
            this.$router.go(-1);
        },
        createPlan() {
            if (this.selectedRow.length > 0) {
                this.$router.push({
                    name: "CreateRoutePlanCompany",
                    params: {
                        items: this.selectedRow,
                        // items: this.customers,
                    },
                });
            } else {
                this.$store.commit("Toast_State", {
                    value: true,
                    color: "error",
                    msg: "ກາລຸນາເລືອກບ້ານ ແລະ ລູກຄ້າກ່ອນ",
                });
            }
            // this.$router.push({name: 'CreatePlan'});
        },
        AddModal(){
            this.$store.commit("modalAdd_State", true);
        },
        AddItem() {
            if (this.$refs.form.validate() == true) {
                this.Submit();
            }
        },
        Submit() {
            this.loading = true;
            const customer = [];
            this.customers.map(item=>{
                customer.push(item.id);
            })
            const  data = {
                name:this.routePlan.name,
                description:this.routePlan.description,
                embed:this.routePlan.embed,
                color_code:this.routePlan.color_code,
                customers:this.customers,
            }
            this.$admin.post('route-plan', data)
                .then(res => {
                    if (res.data.success == true) {
                        setTimeout(() => {
                            this.loading = false;
                            this.$router.push({name: 'Plan'});
                            this.$store.commit("Toast_State", this.toast);
                        }, 1000);
                    }
                }).catch(error => {
                this.loading = false;
                this.$store.commit("Toast_State", this.toast_error);
                this.fetchData();
                if (error.response.status == 422) {
                    let obj = error.response.data.errors;
                    for (let [key, truck] of Object.entries(obj)) {
                        this.server_errors[key] = truck[0];
                    }
                }
            });
        },
        CloseModalAdd() {
            this.truck = {},
            this.$store.commit("modalAdd_State", false);
        },

        async fetchData() {
            this.TableLoading = true;
            this.$admin.get('custom-customers',{
                params: queryOption([
                    { page: this.pagination.current_page },
                    { per_page: this.per_page },
                    { has_plan: this.has_route_plan},
                    { district_id:this.selectedDistrict },
                    { village_id:this.selectedVillages },
                    { search:this.search },
                ]),
            }).then(res => {
                this.customers = res.data.customers.data;
                this.pagination = res.data.customers;
                this.TableLoading = false;
            }).catch(() => {
                this.TableLoading = false;
                this.$store.commit("Toast_State", this.toast_error);
            });
        },
        fetchDistrict() {
            this.$axios.get('list-districts').then(res => {
                setTimeout(() => {
                    this.districts = res.data.listDistricts;
                }, 100);
            }).catch(() => {
                this.$store.commit("Toast_State", this.toast_error);
            });
        },
        FilterVillages() {
            this.$admin
                .get("list-ຈຸດສັງເກດ")
                .then((res) => {
                    this.listVillages = res.data.listVillages;
                })
                .catch(() => {
                    this.TableLoading = false;
                });
        },

        closeDelete() {
            this.$store.commit("modalDelete_State", false);
        },
        deleteItem(id) {
            this.truckID = id;
            this.$store.commit("modalDelete_State", true);
        },
        deleteItemConfirm() {
            this.loading = true;
            this.$admin.delete('delete-water-truck/' + this.truckID).then(res => {
                if (res.data.success == true) {
                    setTimeout(() => {
                        this.getData();
                        this.$store.commit("modalDelete_State", false);
                        this.loading = false;
                        this.$store.commit("Toast_State", this.toast);
                    }, 1000);
                }
            }).catch(() => {
                this.$store.commit("modalDelete_State", false);
                this.loading = true;
                this.$store.commit("Toast_State", this.toast_error);
            })
        },
        //Google map
        getSiteIcon() {
            const pin1 = {
                // url: require("@/assets/vari_pin.png"),
                url:"",
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false,
                size: {
                    width: 35,
                    height: 55,
                    f: "px",
                    b: "px",
                },
                scaledSize: {
                    width: 35,
                    height: 55,
                    f: "px",
                    b: "px",
                },
            };
            return pin1;
        },
        getCenter() {
            if (this.customers.length) {
                if (parseFloat(this.customers[0].latitude) == null) {
                    return this.latlng;
                } else {
                    const latlng = {
                        lat: parseFloat(this.customers[0].latitude),
                        lng: parseFloat(this.customers[0].longitude),
                    };
                    return latlng;
                }
            }
            return this.latlng;
        },
        getMarkers(m) {
            if (m.latitude !== null) {
                return {
                    lat: parseFloat(m.latitude),
                    lng: parseFloat(m.longitude),
                };
            }
        },
        toggleInfo(m, key) {
            this.infoPosition = this.getMarkers(m);
            this.infoContent = m.name + " (" + m.phone + ") ";
            if (this.infoCurrentKey == key) {
                this.infoOpened = !this.infoOpened;
            } else {
                this.infoOpened = true;
                this.infoCurrentKey = key;
            }
        },

        // toggle() {
        //     this.$nextTick(() => {
        //         if (this.selectedAllVillage) {
        //             this.selectedVillage = [];
        //         } else {
        //             setTimeout(() => {
        //                 this.selectedVillage = this.villages.slice();
        //             }, 300);
        //         }
        //     });
        // },

        SelectedCustomer(){
            if (this.selectedRow.length > 0) {
                this.$router.push({
                    name: "SelectedCreatePlan",
                    params: {
                        items: this.selectedRow,
                    },
                });
            } else {
                this.$store.commit("Toast_State", {
                    value: true,
                    color: "error",
                    msg: "ກາລຸນາລູກຄ້າກ່ອນ",
                });
            }
        },
        checkBox(value) {
                this.pagination.current_page = '';
                if(value == true) {
                    this.has_route_plan = 1;
                } else {
                    this.has_route_plan = 0;
                }
                this.fetchData();
        },
        Search() {
            GetOldValueOnInput(this);
        },
    },
    watch: {
        selectedDistrict: function () {
            this.pagination.current_page = '';
            this.fetchData();
        },
        selectedVillages:function (){
            this.pagination.current_page = '';
            this.fetchData();
        },
        "search":function () {
            this.pagination.current_page = '';
            setTimeout( ()=>{
                this.fetchData();
            },1000)
        }
    },
    created() {
    },
}
